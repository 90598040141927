import { ReactNode } from "react";

export interface TextGradientProps {
  children: ReactNode;
  className?: string,
}

const TextGradient = ({ children, className = ''}: TextGradientProps) => <div
  style={{
    background: 'linear-gradient(41.45deg, #D946EF 4.15%, #4F46E5 79%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
  }}
  className={className}
>
  {children}
</div>

export default TextGradient;
