import Image from "next/image";

type Prop = {
  title: string
  imgPath: string
  desc: string
  h: number
  w: number
}
const CapabilityCard = ({ title, imgPath, desc } : Prop) => {
  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-3 items-start">
        <Image alt={title} src={imgPath} height={115} width={135} />
        <span className="h6 font-montserrat max-w-[190px]">{title}</span>
      </div>
      <div className="body1">
        <span>{desc}</span>
      </div>
    </div>
  )
};

export default CapabilityCard;
