import { useRouter } from "next/router";
import { Z_INDECES } from "@app/constants/default";
import { ABOUT_PAGE } from "@app/constants/routes";
import { Parallax } from "react-scroll-parallax";
import Image from "next/image";
import Button from "../button";

const GetToKnow = () => {
  const router = useRouter();

  return (
    <>
      <div className="relative">
        <div
          className="absolute
          -top-10 sm:-top-4 lg:-top-12"
          style={{
            zIndex: Z_INDECES.FLOATING_COMPONENT + 2,
          }}
        >
          <Parallax
            translateY={[-10, 0]}
            translateX={[-150, -130]}
            rotate={[-20, 20]}
          >
            <Image
              alt="donut"
              src="/assets/svgs/home/3d-donut.svg"
              height={440}
              width={345}
              layout="fixed"
            />
          </Parallax>
        </div>
        <div
          className="absolute 
            top-20 sm:top-28 md:top-32 lg:top-40
            left-20 sm:left-0 lg:left-20"
        >
          <Parallax
            translateY={[-20, 10]}
            translateX={[-150, -140]}
          >
            <Image
              alt="blob"
              src="/assets/svgs/home/blob-gradient-01.svg"
              height={560}
              width={601}
              layout="fixed"
            />
          </Parallax>
        </div>
      </div>
      <div
        className="relative overflow-hidden 2xl:overflow-visible self-end 2xl:self-center 2xl:w-[1280px] xs:z-auto z-40"
      >
        <div
          className="gradient-border-indigo rounded-[40px] 
        -right-[360px] sm:-right-[290px] md:-right-[260px] lg:-right-[167px] xl:-right-[39px] 2xl:-right-[263px]
        "
        >
          <div className="flex justify-center w-[1056px] p-16 lg:20">
            <div className="w-[253px] xs:w-[293px] sm:w-[390px] md:w-[453px] lg:w-[520px] flex flex-col space-y-6 items-start">
              <span className="h3 md:h2 font-montserrat">
                It’s worth choosing us
              </span>
              <span className="body-1 md:sub-heading">
                We are dedicated to generating real change through technology.
                <br /> We construct and innovate in technology with a purpose.
              </span>
              <span className="body-1 md:sub-heading">
                Rapidly implemented operational improvements are incorporated
                into digital operations, providing you with value that is
                unmatched.
              </span>
              <Button
                onClick={() => router.push(ABOUT_PAGE)}
                buttonType="gradient"
                paddingSize="lg"
                radiusSize="2xl"
              >
                Get to know Cogitate
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetToKnow;
