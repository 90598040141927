import { chunk } from "lodash";
import CapabilityCard from "./card";

const CapabiliitesCards = () => {
  const capabilities = [
    {
      title: "Modern Development",
      imgPath: "/images/home/3d-mod-d.png",
      desc: "Forget old legacy code. Enjoy working on innovative projects that utilize modern tech stacks to build amazing products.",
      h: 105,
      w: 135
    },
    {
      title: "Augmented Reality",
      imgPath: "/images/home/3d-ar.png",
      desc: "Work with cutting edge technologies and hardware. Experience the future of software development.",
      h: 111,
      w: 135
    },
    {
      title: "Mobile Development",
      imgPath: "/images/home/3d-mob-d.png",
      desc: "Full service systems need to be available on every platform. We provide both iOS and Android development opportunities.",
      h: 124,
      w: 135
    },
    {
      title: "Enterprise Systems",
      imgPath: "/images/home/3d-es.png",
      desc: "Complex products require distributed systems that employ atypical techniques and services. Expand your experience and capabilities.",
      h: 105,
      w: 135
    },
  ];
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 sm:col-span-6 grid-grid-cols-12 gap-6 flex flex-col items-end">
        {chunk(capabilities, 2)[0].map((capability) => (
          <div
            key={capability.title}
            className={`gradient-border p-8 rounded-2xl
          col-span-12 sm:col-span-6 md:w-[287px]`}
            style={{
              background:
                "linear-gradient(103.9deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5))",
              backdropFilter: "blur(28px)",
              WebkitBackdropFilter: "blur(28px)",
            }}
          >
            <CapabilityCard
              title={capability.title}
              desc={capability.desc}
              imgPath={capability.imgPath}
              h={capability.h}
              w={capability.w}
            />
          </div>
        ))}
      </div>
      <div className="col-span-12 sm:col-span-6 grid-grid-cols-12 gap-6 relative flex flex-col items-start">
        {chunk(capabilities, 2)[1].map((capability) => (
          <div
            key={capability.title}
            className={`absolute gradient-border p-8 rounded-2xl
          col-span-12 sm:col-span-6 md:w-[287px] sm:top-28`}
            style={{
              background:
                "linear-gradient(103.9deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5))",
              backdropFilter: "blur(28px)",
              WebkitBackdropFilter: "blur(28px)",
            }}
          >
            <CapabilityCard
              title={capability.title}
              desc={capability.desc}
              imgPath={capability.imgPath}
              h={capability.h}
              w={capability.w}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CapabiliitesCards;
