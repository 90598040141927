import { BLOG_PAGE } from "@app/constants/routes";
import Button from "@app/components/button";
import TextGradient from "@app/components/text-gradient";
import { Z_INDECES } from "@app/constants/default";
import { useRouter } from "next/router";

const FeaturedNewsCard = () => {
  const router = useRouter();
  return (
    <div
      className="gradient-border-indigo-thin rounded-2xl  lg:max-w-[423px]"
      style={{
        background:
          "linear-gradient(103.9deg, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1))",
        backdropFilter: "blur(28px)",
        WebkitBackdropFilter: "blur(28px)"
      }}
    >
      <div
        className="sticky min-h-[200px] h-full w-full bg-cover bg-top bg-no-repeat"
        style={{
          backgroundImage: "url('/images/home/featured-01.png')",
          zIndex: Z_INDECES.BACKGROUND_ASSETS,
        }}
      />
      <div className="flex flex-col items-start p-8 space-y-6">
        <div>
          <TextGradient>
            <span className="font-black">NEWS</span>
          </TextGradient>
          <span className="h5">The Philippine Expansion</span>
        </div>
        <span className="body-1">
          We are really excited to work with the people in the Philippines. The
          work ethic and teamwork generally observed is impressive and is a
          great cultural fit for the company culture we are fostering.
        </span>
        <Button
          buttonType="white"
          paddingSize="custom-irregular"
          radiusSize="custom-half"
          fontSize="label-md"
          onClick={() => router.push(`${BLOG_PAGE}/the-philippine-expansion`)}
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export default FeaturedNewsCard;
