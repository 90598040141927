import Image from "next/image";

const CapabiliitesHeader = () => {
  return (
    <div className="lg:mt-32 flex flex-col space-y-8 text-custom-white lg:max-w-[266px] xl:max-w-[410px] 2xl:max-w-[663px]">
      <div className="flex flex-col items-start space-y-4">
        <div className="flex items-center space-x-[2px]">
          <Image
            alt="C"
            src="/assets/svgs/global/logo-icon-white.svg"
            height={32}
            width={32}
            layout="fixed"
          />
          <span className="h4 font-montserrat">apabilities</span>
        </div>
        <div className="w-[120px] h-2 bg-gradient-to-tr from-custom-pink to-custom-violet" />
      </div>
      <div className="flex flex-col space-y-2">
        <span className="h3 font-montserrat">Think Deeply</span>
        <span className="sub-heading">
          Exert the ability to ponder — have a view of one&apos;s mind. Cogitating
          necessities and possibilities of every grave decision on sustainable
          growth.
        </span>
      </div>
    </div>
  );
};

export default CapabiliitesHeader;
