import BgBalls from "@app/components/home-components/bg-balls";
import Capabiliites from "@app/components/home-components/capabilites";
import FeaturedNews from "@app/components/home-components/featured-news";
import GetToKnow from "@app/components/home-components/get-to-know";
import HomeIntroText from "@app/components/home-components/intro-text";
import Vision from "@app/components/home-components/vision";
import { NAV_HEIGHT } from "@app/constants/default";

export const HomePage = () => {
  return (
    <div className="overflow-hidden" style={{
      paddingTop: NAV_HEIGHT
    }}>
      <BgBalls />
      <div
        className="pt-[98px] sm:pt-[124px] md:pt-[131px] lg:pt-[207px]"
        style={{
          background:
            "linear-gradient(180deg, #010317 18.72%, #170057 54.71%, #05071B 100%)",
        }}
      >
        <div
          className="lg:h-[520px] xs:h-[322px] h-[230px]"
          style={{ transition: "height 1s" }}
        />
        <div className="w-full flex flex-col items-center">
          <HomeIntroText />
          <Capabiliites />
          <Vision />
          <GetToKnow />
          <FeaturedNews />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
