import { Z_INDECES } from "@app/constants/default";
import { useThemeContext } from "@app/contexts/theme-context";
import Image from "next/image";
import { Parallax } from "react-scroll-parallax";

const BgBalls = () => {
  const { isScrollPassedNav } = useThemeContext();
  return (
    <div className="relative">
      <div
        className="absolute tansition-all duration-1000
        -right-[500px] sm:-right-[468px] md:-right-[398px] lg:-right-[260px] xl:-right-[150px] 2xl:-right-1 
        md:top-40"
      >
        <Parallax translateY={[-150, 20]}>
          <Image
            alt="big-ball-1"
            src="/assets/svgs/home/3d-bigball-01.svg"
            height={498}
            width={498}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
        right-[180px] sm:right-[380px] md:right-[440px] lg:right-[580px] xl:right-[750px] 2xl:right-[55%]"
      >
        <Parallax 
          translateY={[710, -1]}
          translateX={[80, 300]}
        >
          <Image
            alt="smol-ball-2"
            src="/assets/svgs/home/3d-smolball-02.svg"
            height={110}
            width={110}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
        left-[70px] md:left-[140px] lg:left-1/4 2xl:left-1/3 "
      >
        <Parallax translateY={[80, 20]}>
          <Image
            alt="big-ball-2"
            src="/assets/svgs/home/3d-bigball-02.svg"
            height={395}
            width={395}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
        left-[270px] md:left-[320px] lg:left-[455px]  xl:left-[565px] 2xl:left-[47%] "
      >
        <Parallax 
          translateY={[170, 90]}
          translateX={[-100, -300]}
        >
          <Image
            alt="mid-ball-1"
            src="/assets/svgs/home/3d-midball-01.svg"
            height={192}
            width={192}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
        -left-[404px] md:-left-[370px] lg:-left-[240px] xl:-left-[100px] 2xl:left-2"
      >
        <Parallax translateY={[300, -40]}>
          <Image
            alt="big-ball-3"
            src="/assets/svgs/home/3d-bigball-03.svg"
            height={404}
            width={404}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
        top-32
        -right-[241px] sm:-right-[110px] md:-right-[55px] lg:right-[70px] xl:right-[190px] 2xl:right-[20%]"
        style={{
          zIndex: isScrollPassedNav ? 0 : Z_INDECES.NAVBAR + 1
        }}
      >
        <Parallax 
          translateY={[10, 170]}
          translateX={[-80, 240]}
          rotate={[180, 0]}
        >
          <Image
            alt="mid-ball-3"
            src="/assets/svgs/home/3d-midball-03.svg"
            height={241}
            width={241}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
          -right-[150px] sm:right-[40px] md:right-[120px] lg:right-[250px] xl:right-[370px] 2xl:right-[500px]"
      >
        <Parallax 
          translateY={[-70, 20]}
        >
          <Image
            alt="mid-ball-2"
            src="/assets/svgs/home/3d-midball-02.svg"
            height={196}
            width={196}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
          -right-[100px] sm:right-[120px] md:right-[175px] lg:right-[300px] xl:right-1/3 2xl:right-[36%]"
      >
        <Parallax 
          translateY={[-170, 350]}
          translateX={[-100, 400]}
        >
          <Image
            alt="smol-ball-1"
            src="/assets/svgs/home/3d-smolball-01.svg"
            height={94}
            width={94}
            layout="fixed"
          />
        </Parallax>
      </div>
      <div
        className="absolute tansition-all duration-1000
        -left-[350px] lg:-left-[140px] xl:-left-[5px] 2xl:left-[8%]"
      >
        <Parallax 
          translateY={[300, 50]}
          translateX={[200, 150]}
        >
          <Image
            alt="smol-ball-3"
            src="/assets/svgs/home/3d-smolball-03.svg"
            height={97}
            width={97}
            layout="fixed"
          />
        </Parallax>
      </div>
    </div>
  );
};

export default BgBalls;
