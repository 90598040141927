import { Z_INDECES } from "@app/constants/default";
import { useThemeContext } from "@app/contexts/theme-context";
import Image from "next/image";
import { Parallax } from "react-scroll-parallax";
import FeaturedNewsCard from "./card";
import FeaturedNewsHeader from "./header";

const FeaturedNews = () => {
  const { isTablet } = useThemeContext();

  return (
    <div>
      <div className="relative">
        <div className="absolute
          -right-[400px] sm:-right-[430px] lg:-right-[380px] xl:-right-[300px] 2xl:-right-96 
          top-[390px] sm:top-[350px] lg:top-60 2xl:top-60">
          <Parallax 
            translateX={[50, -10]}
            rotate={[isTablet ? 40 : 10, 0]}
            >
            <Image
              alt="long"
              src="/assets/svgs/home/3d-long-01.svg"
              height={539}
              width={869}
              layout="fixed"
            />
          </Parallax>
        </div>
      </div>
      <div
        className="sticky flex flex-col lg:flex-row items-start w-full 
        py-20 px-8 sm:px-20 sm:py-10 lg:py-12
        sm:mb-[60px] md:mb-[100px] lg:mb-10 lg:mt-[80px] xl:mb-20
        space-y-10 lg:space-y-0 lg:space-x-10 md:items-center
        xl:max-w-[1024px] xl:mx-auto"
        style={{
          zIndex: Z_INDECES.FLOATING_COMPONENT,
        }}
      >
        <FeaturedNewsHeader />
        <FeaturedNewsCard />
      </div>
    </div>
  );
};

export default FeaturedNews;
