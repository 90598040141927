import { Z_INDECES } from "@app/constants/default";
import { HOME_PAGE } from "@app/constants/routes";
import Image from "next/image";
import { useRouter } from "next/router";
import { Parallax } from "react-scroll-parallax";
import CapabiliitesCards from "./cards";
import CapabiliitesHeader from "./header";

const Capabiliites = () => {
  const router = useRouter();
  const isAtHomePage = router.pathname === HOME_PAGE;

  return (
    <div>
      {isAtHomePage && (
        <div className="relative">
          <div
            className="absolute 
          top-64 sm:top-[345px] md:top-[280px] lg:top-8 2xl:top-0
          -left-72 sm:-left-40 md:-left-20 lg:left-48 xl:left-40 2xl:left-0"
          >
            <Parallax translateY={[50, -90]} translateX={[120, -110]}>
              <Image
                alt="snake"
                src="/assets/svgs/home/3d-snake.svg"
                height={925}
                width={1050}
                layout="fixed"
              />
            </Parallax>
          </div>
        </div>
      )}
      <div
        className="sticky flex flex-col lg:flex-row items-start w-full 
        p-6 xs:p-10 mb-12 sm:mb-28 md:p-16 xl:pb-20 xl:max-w-[1168px] 2xl:max-w-[1280px] 2xl:px-0 xl:mx-auto 
        space-y-10 lg:space-y-0 lg:space-x-10 md:items-center"
        style={{
          zIndex: Z_INDECES.FLOATING_COMPONENT,
        }}
      >
          <CapabiliitesHeader />
          <CapabiliitesCards />
      </div>
    </div>
  );
};

export default Capabiliites;
