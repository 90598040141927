import Image from "next/image";

const FeaturedNewsHeader = () => {
  return (
    <div className="flex flex-col space-y-8 text-custom-white lg:max-w-[364px] xl:max-w-[410px]">
      <div className="flex flex-col items-start space-y-4">
        <div className="flex items-center space-x-[2px]">
          <Image
            alt="C"
            src="/assets/svgs/global/logo-icon-white.svg"
            height={32}
            width={32}
            layout="fixed"
          />
          <span className="h4 font-montserrat">onnect</span>
        </div>
        <div className="w-[83px] h-2 bg-gradient-to-tr from-custom-pink to-custom-violet" />
      </div>
      <div className="flex flex-col space-y-2">
        <span className="h3 font-montserrat">Featured News</span>
        <span className="sub-heading">
          Upcoming and exciting news for Cogitate are featured here.
        </span>
      </div>
    </div>
  );
};

export default FeaturedNewsHeader;
