import Image from "next/image";
import { Z_INDECES } from "@app/constants/default";
import { Parallax } from "react-scroll-parallax";
const HomeIntroText = () => {
  return (
    <div className="self-start xs:self-center">
      <div className="relative">
        <div
          className="absolute tansition-all duration-1000
          -left-52 xs:-left-44 sm:-left-60 md:-left-72 lg:-left-60 2xl:left-0
          top-16 md:top-20 xl:top-24 2xl:top-28"
        >
          <Parallax translateX={[-10, 10]} scale={[0.8, 1]}>
            <Image
              alt="text-bg"
              src="/images/home/intro-bg.png"
              height={330}
              width={522}
              layout="fixed"
            />
          </Parallax>
        </div>
      </div>
      <div
        className="sticky flex items-start space-x-1 p-6 pb-[50px] sm:pb-[120px] md:pb-16 lg:pb-[180px]
        2xl:ml-72"
        style={{
          zIndex: Z_INDECES.FLOATING_COMPONENT,
        }}
      >
        <div className="h-8 w-8 md:h-16 md:w-16">
          <Image
            alt="C"
            src="/assets/svgs/global/logo-icon-white.svg"
            height={32}
            width={32}
            layout="responsive"
          />
        </div>
        <div
          className="text-custom-white text-justify font-black font-montserrat 
        text-base leading-8 xs:text-2xl xs:leading-10 md:text-4xl md:leading-[56px] drop-shadow-lg
        max-w-[235px] xs:max-w-[344px] sm:max-w-[444px] md:max-w-[527px] lg:max-w-[655px]"
        >
          reating extraordinary <br />
          <span className="md:text-custom-purple">technology</span> &{" "}
          <span className="md:text-custom-violet">experiences</span> paired with
          powerful, first principles reasoning. We strive to provide an
          invigorating environment where creativity is unleashed.
        </div>
      </div>
    </div>
  );
};

export default HomeIntroText;
