import Image from "next/image";
import { Z_INDECES } from "@app/constants/default";

const Vision = () => {
  const visions = [
    {
      title: "ultivate",
      desc: "Develop a great atmosphere in our working environment by promoting individual’s growth, culture, and improvement through technology and innovation.",
    },
    {
      title: "reate",
      desc: "Produce human-centered design and imaginative effort by providing technological solutions that flourish experience and enhance individual’s capability.",
    },
    {
      title: "onnect",
      desc: "Bring everyone together and establish a real bridge between your needs and wants. Elevating all potential experience no matter the path and obstacle you’ll face on your way.",
    },
  ];
  return (
    <div className="grid grid-cols-12  gap-y-8 sm:gap-8 lg:gap-16 lg:max-w-[1024px]
      py-20 px-10 lg:px-16 sm:py-10 sm:mb-20 sm:mt-20 md:mb-36 md:mt-4 lg:mt-12"
      style={{
        zIndex: Z_INDECES.FLOATING_COMPONENT + 3
      }}>
      {visions.map((vision) => (
        <div key={vision.title} className="col-span-12 sm:col-span-4 flex flex-col space-y-4">
          <div className="flex space-x-[2px] items-center">
            <Image
              alt="C"
              src="/assets/svgs/global/logo-icon-white.svg"
              height={32}
              width={32}
              layout="fixed"
            />
            <span className="h4 font-montserrat">{vision.title}</span>
          </div>
          <div className="body2">{vision.desc}</div>
        </div>
      ))}
    </div>
  );
};

export default Vision;
